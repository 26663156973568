import React, {useEffect, useState} from "react";
import {RequireAuth} from "Components/Security/RequireAuth";
import {DefaultContainer} from "Views/Shared/DefaultContainer";
import {Col, Form, Row} from "react-bootstrap";
import {AdminActivityForm} from "Views/Activities/AdminActivityForm";
import {useMutation, useQuery} from "react-query";
import {getActivity} from "Api/Query/ActivityQuery";
import {useParams} from "react-router-dom"
import {ErrorMessageBuilder} from "Services/Notificator/ErrorMessageBuilder";
import {Notificator} from "Services/Notificator/Notificator";
import {appLoading} from "Store/loadingSlice";
import {useDispatch} from "react-redux";
import {ACTIVITY_TYPE_WEEKLY_ID, ACTIVITY_TYPE_YEARLY_ID} from "Components/Activities/ActivityTypeSelect";
import {updateActivity, uploadActivityMedia} from "Api/Mutation/ActivityMutation";

export const AdminEditActivity: React.FC = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    let globalFormData: any = null

    const [defaultValues, setDefaultValues] = useState<any>(null)

    const activityQuery = useQuery({
        queryKey: ['getAdminActivityDetail', id],
        queryFn: () => getActivity(id ?? ''),
        onSuccess: async (response: any) => {
            const activity = response.data._result

            let centerIds: string[] = []
            activity.centers.map((center:any) => centerIds.push(center.id))

            let discountIds: string[] = []
            activity.discounts.map((item:any) => discountIds.push(item.id))

            let defaultValues: any = {
                name: activity.name ?? '',
                type: activity.type ?? '',
                centerIds: centerIds ?? [],
                centers: activity.centers,
                discountIds: discountIds ?? [],
                discounts: activity.discounts,
                initDate: activity.startDate ?? "",
                endDate: activity.endDate ?? "",
                initPaymentDate: activity.initPaymentDate ?? "",
                endPaymentDate: activity.endPaymentDate ?? "",
                description: activity.description ?? "",
                frontImage: activity.image ?? "",
                backgroundImage: activity.backgroundImage ?? "",
                sessions: activity.sessions,
                yearDays: activity.activityYearDays ?? [],
                services: [],
                cancellationLocked: activity.cancellationLocked ?? false
            }

            // images
            if (defaultValues.frontImage) {
                const imagePath = defaultValues.frontImage;
                defaultValues = {...defaultValues, frontImageSrc: imagePath}
            }
            if (defaultValues.backgroundImage) {
                const imagePath = defaultValues.backgroundImage;
                defaultValues = {...defaultValues, backgroundImageSrc: imagePath}
            }

            // Get services by ActivitySession or YearDays properties
            if (activity.type === ACTIVITY_TYPE_YEARLY_ID && activity.activityYearDays.length) {
                const services: any = []
                const insertedServiceIds: any = []

                activity.activityYearDays.map(yearDay => {
                    yearDay?.services.map(service => {
                        if (!insertedServiceIds.includes(service.id)) {
                            services.push(service)
                            insertedServiceIds.push(service.id)
                        }
                    })
                })

                if (services) {
                    defaultValues = {...defaultValues, services: services}
                }
            }

            setDefaultValues(defaultValues)
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
            dispatch(appLoading({value: false}))
        }
    })

    const uploadMutation = useMutation({
        mutationFn: (body: any) => uploadActivityMedia(body.activityId, body.file, body.tag),
        onMutate: (body: any) => {
            dispatch(appLoading({value: true, text: `Subiendo archivo: ${body.file.file.name} ...`}))
        },
        onError: (error: any) => {
            dispatch(appLoading({value: false}))
            Notificator.error('Ha ocurrido un error al subir la imagen. Inténtalo de nuevo.', 'Error')
        },
        onSuccess: () => {

        }
    })

    useEffect(() => {
        if (saveMutation.isLoading) {
            return
        }

        dispatch(
            appLoading({
                value: activityQuery.isLoading,
                text: 'Cargando'
            })
        )
    }, [activityQuery.isLoading])

    const saveMutation = useMutation({
        mutationFn: (body: any) => updateActivity(
            body.activityId,
            body.centerIds,
            body.name,
            body.startDate,
            body.endDate,
            body.initPaymentDate,
            body.endPaymentDate,
            body.description,
            body.discounts,
            body.services,
            body.yearDays,
            body.cancellationLocked
        ),
        onSuccess: async (response: any) => {

            // Upload frontImage
            if (globalFormData && typeof globalFormData.frontImage === 'object') {
                await uploadMutation.mutateAsync({
                    activityId: id,
                    file: globalFormData.frontImage,
                    tag: 'frontImage'
                })
            }

            // Upload backgroundImage
            if (globalFormData?.backgroundImage && typeof globalFormData.backgroundImage === 'object') {
                await uploadMutation.mutateAsync({
                    activityId: id,
                    file: globalFormData.backgroundImage,
                    tag: 'backgroundImage'
                })
            }

            activityQuery.refetch()

            Notificator.success('La actividad se ha actualizado correctamente.')
        },
        onError: (error: any) => {
            const notificationMessage = ErrorMessageBuilder.create(error)
            Notificator.error(notificationMessage, 'Error')
        }
    })

    useEffect(() => {
        if (activityQuery.isLoading) {
            return
        }

        dispatch(
            appLoading({
                value: saveMutation.isLoading,
                text: 'Cargando'
            })
        )
    }, [saveMutation.isLoading])

    const submit = (formData: any, isValid: boolean): void => {
        globalFormData = formData

        if (!isValid) {
            return
        }

        let services: any[] = []
        let yearDays: any[] = []

        if (formData.type === ACTIVITY_TYPE_YEARLY_ID) {
            formData.services?.map((service: any) => {
                const serviceId : string = service.id !== undefined ? service.id : service
                services.push(serviceId)
            })
            yearDays = formData.yearDays
        }

        saveMutation.mutate({
            activityId: id,
            centerIds: formData.centerIds,
            name: formData.name,
            startDate: formData.initDate,
            endDate: formData.endDate,
            initPaymentDate: formData.initPaymentDate,
            endPaymentDate: formData.endPaymentDate,
            description: formData.description,
            discounts: formData.discountIds,
            services: services,
            yearDays: yearDays,
            cancellationLocked: formData.cancellationLocked
        })
    }

    return (
        <RequireAuth>
            <DefaultContainer>

                <Col id={"mainRequestList"}>

                    <Row>
                        <Col>
                            <h1 className={"pb-4"}>Detalle de actividad</h1>
                        </Col>
                    </Row>

                    <br/>
                    <Form.Group controlId={"addActivity"} className={"mb-4"}>

                        {defaultValues &&
                            <AdminActivityForm
                                onSubmit={submit}
                                defaultValues={defaultValues}
                                isEdit={true}
                            />
                        }
                    </Form.Group>

                </Col>

            </DefaultContainer>
        </RequireAuth>
    )
}
